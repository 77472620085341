

import Vue from "vue"
import store from '@/store/index'

export default Vue.extend({
	name: "LinkCollection",
    beforeRouteEnter(to, from, next) {
        // eslint-disable-next-line
        const links = (store.state as any).nav.linkCollection as any;
        if(links.length < 1){
            next(false)
            console.log("No links available.")
        }
        else if(links.length == 1){
            next(false)
            window.open(links[0], '_blank')
        } else {
            next()
        }
    },
})
